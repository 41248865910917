import { useState } from "react";
import { useFetchBackend } from "./utils";
import { Link, useNavigate } from "react-router-dom";

const NewStory = () => {
	const [storyIds, setStoryIds] = useState<string[]>(
		JSON.parse(localStorage.getItem("storyIds") ?? "[]")
	);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [location, setLocation] = useState("");
	const [error, setError] = useState<number | undefined>();
	const navigate = useNavigate();

	const fetchBackend = useFetchBackend();
	const start = async () => {
		setLoading(true);

		const rsp = await fetchBackend(
			"/start",
			"POST",
			JSON.stringify({
				name,
				location,
			})
		);

		if (!rsp.ok) {
			setLoading(false);
			setError(rsp.status);
			return;
		}

		const json = await rsp.json();
		const id = json.id as string;

		setError(undefined);
		setLoading(false);
		const newStoryIds = [id, ...storyIds];
		setStoryIds(newStoryIds);
		localStorage.setItem("storyIds", JSON.stringify(newStoryIds));
		navigate(`/story/${encodeURIComponent(id)}`);
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				start();
			}}
		>
			{error ? (
				<div style={{ color: "#ff0000", fontWeight: "bold" }}>{error}</div>
			) : null}
			<p>
				<Link to="/">All stories</Link>
			</p>
			<p>Choose your character name</p>
			<input
				disabled={loading}
				placeholder="Name"
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>
			<p>Choose your location</p>
			<input
				disabled={loading}
				placeholder="Location"
				value={location}
				onChange={(e) => setLocation(e.target.value)}
			/>
			<div style={{ marginTop: 16 }}>
				<button disabled={loading} type="submit">
					Start
				</button>
			</div>
		</form>
	);
};

export default NewStory;
