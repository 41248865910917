import { AuthContextProvider } from "./AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Stories from "./Stories";
import NewStory from "./NewStory";
import Story from "./Story";
import Layout from "./Layout";
import StoryLayout from "./StoryLayout";
import CodeLayout from "./CodeLayout";
import Code from "./Code";

function App() {
	return (
		<div
			style={{
				fontFamily: "sans-serif",
				maxWidth: 640,
				margin: "0 auto",
				lineHeight: "150%",
				paddingBottom: "100px",
			}}
		>
			<AuthContextProvider>
				<Router>
					<Routes>
						<Route path="/" element={<Layout />}>
							<Route path="story" element={<StoryLayout />}>
								<Route path="" element={<Stories />} />
								<Route path="new" element={<NewStory />} />
								<Route path=":id" element={<Story />} />
							</Route>
							<Route path="code" element={<CodeLayout />}>
								<Route path="" element={<Code />}></Route>
							</Route>
						</Route>
					</Routes>
				</Router>
			</AuthContextProvider>
		</div>
	);
}

export default App;
