import { useCallback } from "react";
import { useAuth } from "./AuthContext";

const removeLeadingSlash = (route: string) => {
	if (route.startsWith("/")) {
		return route.substring(1);
	}
	return route;
};

export const useFetchBackend = () => {
	const auth = useAuth();
	return useCallback(
		(route: string, method: "POST" | "GET", payload?: string) => {
			const url =
				(process.env.NODE_ENV === "production"
					? "https://gpt3-api.dougsafreno.com/"
					: "http://localhost:8000/") + removeLeadingSlash(route);
			return fetch(url, {
				headers: new Headers({
					Authorization: auth,
					"Content-Type": "application/json",
				}),
				method,
				body: payload ?? null,
			});
		},
		[auth]
	);
};
