import { Outlet } from "react-router-dom";

const StoryLayout = () => {
	return (
		<>
			<p>Welcome to a...</p>
			<h1>GPT-3 story</h1>
			<Outlet />
		</>
	);
};

export default StoryLayout;
