import { Outlet } from "react-router-dom";

const CodeLayout = () => {
	return (
		<>
			<p>Welcome to...</p>
			<h1>The Code Playground</h1>
			<Outlet />
		</>
	);
};

export default CodeLayout;
