import e from "express";
import { useState } from "react";
import { useFetchBackend } from "./utils";

const Code = () => {
	const [prompt, setPrompt] = useState("");
	const [result, setResult] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	const fetch = useFetchBackend();
	const query = async () => {
		setLoading(true);
		setError(null);
		try {
			const rsp = await fetch("/code", "POST", JSON.stringify({ prompt }));
			if (!rsp.ok) {
				setError(rsp.statusText);
				return;
			}
			const { response: result } = (await rsp.json()) as { response: string };
			setResult(result);
		} catch (e) {
			setError((e as Error).toString());
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					query();
				}}
			>
				<div>
					<textarea
						style={{ width: "100%", height: "20vh" }}
						placeholder="Insert prompt"
						value={prompt}
						onChange={(e) => setPrompt(e.target.value)}
					/>
				</div>
				<button type="submit">Submit</button>
			</form>
			{loading ? <p>Loading...</p> : null}
			{error ? <p style={{ color: "#FF0000" }}>{error}</p> : null}
			<pre style={{ marginTop: 20 }}>
				<code>{result}</code>
			</pre>
		</>
	);
};

export default Code;
