import { Outlet, Link, useLocation } from "react-router-dom";

const Tab = ({ path, title }: { path: string; title: string }) => {
	const location = useLocation();

	if (location.pathname.startsWith(path)) {
		<Link to={location.pathname} style={{ fontWeight: 800 }}>
			{title}
		</Link>;
	}
	return <Link to={path}>{title}</Link>;
};

const Layout = () => {
	return (
		<>
			<header>
				<Tab path="/story" title="Story" />
				&nbsp;
				<Tab path="/code" title="Code" />
			</header>
			<Outlet />
		</>
	);
};

export default Layout;
