import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFetchBackend } from "./utils";

type ParagraphPrompt = {
	type: "continue";
	prompt: string;
	response: string;
};

type RandomEventPrompt = {
	type: "randomEvent";
	prompt: string;
	options: string[];
	response: string;
	chosenOption?: number;
};

type Prompt = ParagraphPrompt | RandomEventPrompt;

const ParagraphPromptView = ({ prompt }: { prompt: ParagraphPrompt }) => {
	return <p>{prompt.response}</p>;
};

const RandomEventPromptView = ({
	prompt,
	submitOption,
}: {
	prompt: RandomEventPrompt;
	submitOption: (option: number) => void;
}) => {
	return (
		<>
			{prompt.options.map((option, i) => (
				<p key={i}>{option}</p>
			))}
			{prompt.options.map((option, i) => (
				<button key={i} onClick={() => submitOption(i)}>
					Choose {i + 1}
				</button>
			))}
		</>
	);
};

const RandomEventPromptHistoricalView = ({
	prompt,
}: {
	prompt: Required<RandomEventPrompt>;
}) => {
	return (
		<>
			{prompt.options.map((option, i) => (
				<p
					key={i}
					style={{
						fontWeight: i === prompt.chosenOption ? "bolder" : "unset",
						textDecoration:
							i !== prompt.chosenOption ? "line-through" : "unset",
						opacity: i !== prompt.chosenOption ? "50%" : "unset",
					}}
				>
					{option}
				</p>
			))}
		</>
	);
};

const PromptView = ({
	prompt,
	submitOption,
}: {
	prompt: Prompt;
	submitOption: (option: number) => void;
}) => {
	if (prompt.type === "continue") {
		return <ParagraphPromptView prompt={prompt} />;
	}
	if (prompt.type === "randomEvent") {
		if (prompt.chosenOption !== undefined) {
			return (
				<RandomEventPromptHistoricalView
					prompt={prompt as Required<RandomEventPrompt>}
				/>
			);
		} else {
			return (
				<RandomEventPromptView prompt={prompt} submitOption={submitOption} />
			);
		}
	}
	return <h1>ERROR</h1>;
};

const Story = () => {
	const params = useParams<{ id: string }>();
	const id = decodeURIComponent(params.id!);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<null | number | string>(null);
	const [prompts, setPrompts] = useState<Prompt[]>([]);
	const [hasNext, setHasNext] = useState(false);

	const fetchBackend = useFetchBackend();

	useEffect(() => {
		fetchBackend(`/story/${encodeURIComponent(id)}`, "GET")
			.then(async (rsp) => {
				if (!rsp.ok) {
					setError(rsp.status);
					return;
				}
				setError(null);
				const { prompts, hasNext } = (await rsp.json()) as {
					prompts: Prompt[];
					hasNext: boolean;
				};
				setPrompts(prompts);
				setHasNext(hasNext);
			})
			.catch((e) => {
				setError(e.toString());
			})
			.finally(() => {
				setLoading(false);
			});
	}, [fetchBackend, id]);

	const lastPrompt =
		prompts.length > 0 ? prompts[prompts.length - 1] : undefined;

	const shouldAutoContinue =
		!loading && hasNext && lastPrompt?.type === "continue";

	const locked = useRef(false);
	useEffect(() => {
		console.log("rendering effect", fetchBackend, shouldAutoContinue, id);
		if (!shouldAutoContinue || locked.current) {
			return;
		}
		locked.current = true;
		setLoading(true);
		fetchBackend("/next", "POST", JSON.stringify({ id }))
			.then(async (rsp) => {
				if (!rsp.ok) {
					setError(rsp.status);
					return;
				}
				const { prompt, hasNext } = (await rsp.json()) as {
					prompt: Prompt;
					hasNext: boolean;
				};
				setPrompts((ps) => [...ps, prompt]);
				setHasNext(hasNext);
			})
			.catch((e) => {
				setError(e.toString());
			})
			.finally(() => {
				locked.current = false;
				setTimeout(() => setLoading(false), 100);
			});
	}, [fetchBackend, shouldAutoContinue, id]);

	const numOptions =
		lastPrompt?.type === "randomEvent" ? lastPrompt?.options?.length : -1;
	const submitOption = useCallback(
		async (option: number) => {
			setLoading(true);
			if (option >= numOptions) {
				setError("Invalid option selection");
				return;
			}
			setPrompts((ps) => {
				const p = { ...ps[ps.length - 1] } as RandomEventPrompt;
				p.chosenOption = option;
				return [...ps.slice(0, -1), p];
			});
			try {
				const rsp = await fetchBackend(
					"/respond",
					"POST",
					JSON.stringify({
						id,
						choice: option,
					})
				);
				if (!rsp.ok) {
					setError(rsp.status);
					return;
				}
				const { prompt, hasNext } = (await rsp.json()) as {
					prompt: Prompt;
					hasNext: boolean;
				};
				setPrompts((ps) => [...ps, prompt]);
				setHasNext(hasNext);
			} catch (e: any) {
				setError(e.toString());
			} finally {
				setLoading(false);
			}
		},
		[fetchBackend, numOptions, id]
	);

	const navigate = useNavigate();

	return (
		<>
			<p>
				<Link to="/">All stories</Link>
			</p>
			{prompts.map((p, i) => (
				<PromptView key={i} prompt={p} submitOption={submitOption} />
			))}
			{error ? (
				<p style={{ fontWeight: "bolder", color: "#ff0000" }}>{error}</p>
			) : null}
			{loading ? <p>Generating...</p> : null}
			{!hasNext && !loading ? (
				<>
					<p>"THE END"</p>
					<Link to="/">All stories</Link> &nbsp;
					<button onClick={() => navigate("/new-story")}>New Story</button>
				</>
			) : null}
		</>
	);
};

export default Story;
