import React, { ReactNode, useContext, useState } from "react";

const AuthContext = React.createContext<string>("");

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
	const [auth, setAuth] = useState<null | string>(localStorage.getItem("auth"));

	const [wipAuth, setWipAuth] = useState("");
	if (auth) {
		return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
	}
	return (
		<form
			onSubmit={() => {
				localStorage.setItem("auth", wipAuth);
				setAuth(wipAuth);
			}}
		>
			<input
				value={wipAuth}
				type="password"
				onChange={(e) => setWipAuth(e.target.value)}
				placeholder="Password"
			/>
			<button type="submit">Submit</button>
		</form>
	);
};

export const useAuth = () => useContext(AuthContext);
