import { useState } from "react";
import { Link } from "react-router-dom";

const Stories = () => {
	const [storyIds] = useState<string[]>(
		JSON.parse(localStorage.getItem("storyIds") ?? "[]")
	);

	return (
		<div>
			<p>
				<Link to={"/new-story"}>New story</Link>
			</p>
			{storyIds.map((storyId) => (
				<p>
					<Link key={storyId} to={`/story/${encodeURIComponent(storyId)}`}>
						{new Date(
							storyId.substring(0, storyId.length - 37)
						).toLocaleString()}
					</Link>
				</p>
			))}
		</div>
	);
};

export default Stories;
